import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from 'components/Layout';
import Banner from 'components/Banner';
import FeaturedArticles from 'components/FeaturedArticles';
import ProductsFinder from 'components/ProductsFinder';

import { IUmbracoQuizIncomeData, IUmbracoQuizParsedData } from './models';
import parseUmbracoQuizIncomeData from './parsers';
import BodyRenderer from './helpers';

import './QuizPage.scss';

interface IPropsQuizPage extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[]; featuredProductsLinks: string[] };
  data: IUmbracoQuizIncomeData;
}

const ExpectedStructures = {
  'Page Banner': {
    Component: Banner,
    dataKey: 'banner',
  },
  'Featured Articles': {
    Component: FeaturedArticles,
    dataKey: 'featuredArticles',
  },
  'Start Quiz': {
    Component: ProductsFinder,
    dataKey: 'startQuiz',
  },
};

const QuizPage: FC<IPropsQuizPage> = (props) => {
  const parsedData: IUmbracoQuizParsedData = parseUmbracoQuizIncomeData({
    ...props.data,
    breadCrumbs: props.pageContext.breadcrumbs,
  });

  const {
    allUmbracoQuiz: {
      nodes: [
        { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage, lang },
      ],
    },
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    promoModal,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  } = props.data;

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        ogImage,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      langSettings={{
        currentLang: lang,
        langs,
      }}
      legalPopup={legalPopup}
      promoModal={promoModal}
      className="quiz-page"
    >
      <BodyRenderer bodyData={parsedData} expectedStructures={ExpectedStructures} />
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!, $link: String!, $pageBannerImageSize: Int = 1000, $pageIdRegex: String) {
    allUmbracoQuiz(filter: { lang: { eq: $lang }, link: { eq: $link } }) {
      nodes {
        ...FragmentSeo
        lang
        body {
          structure
          properties {
            ...FragmentBanner
            ...FragmentFeaturedArticles
            ...FragmentStartQuizSection
          }
        }
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    pageLangs: allUmbracoQuiz(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    productQuiz {
      ...FragmentProductQuiz
    }
    ...FragmentCommonCompositions
  }
`;

export default QuizPage;
