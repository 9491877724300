import React from 'react';

const BodyRenderer = ({ bodyData, expectedStructures }) => {
  return (
    <>
      {bodyData.map((item) => {
        const structureData = expectedStructures[item.structure];
        if (!structureData) {
          return null;
        }
        const { Component } = structureData;

        return <Component key={item.key} {...item.properties} />;
      })}
    </>
  );
};

export default BodyRenderer;
