import { parseBanner } from 'components/Banner/parsers';
import { parseNFButton } from 'components/common/NFButton/parsers';

import { IUmbracoQuizIncomeData, IUmbracoQuizParsedData } from './models';

export const parseNameToId = (name) =>
  name &&
  name
    .trim()
    .toLowerCase()
    .replace(/[^A-Z0-9]/gi, '-');

const parseUmbracoQuizIncomeData = (param: IUmbracoQuizIncomeData): IUmbracoQuizParsedData => {
  const result = param.allUmbracoQuiz.nodes[0].body.map((item, index) => {
    let itemParsedData;
    switch (item.structure) {
      case 'Page Banner':
        itemParsedData = {
          ...parseBanner(item.properties, {
            breadCrumbs: param.breadCrumbs,
          }),
        };
        break;
      case 'Featured Articles':
        itemParsedData = {
          bgColor: item?.properties?.featuredArticlesBgColor,
          title: item?.properties?.featuredArticlesSectionTitle,
          subtitle: item?.properties?.featuredArticlesSectionSubtitle,
          btn: item?.properties?.featuredArticlesCTAButtonData?.length
            ? { ...parseNFButton(item?.properties?.featuredArticlesCTAButtonData[0].properties) }
            : undefined,
          articles: item?.properties?.featuredArticlesData,
          mode: item?.properties?.featuredArticlesSelectedArticleMode,
          carouselControls: param.carouselControls,
        };
        break;
      case 'Start Quiz':
        itemParsedData = {
          startQuiz: {
            startQuizBoldTitle: item?.properties?.startQuizBoldTitle,
            startQuizLightTitle: item?.properties?.startQuizLightTitle,
            startQuizAriaLabel: item?.properties?.startQuizAriaLabel,
          },
          productQuizData: param?.productQuiz,
          lang: param?.pageLangs?.nodes?.[0]?.lang,
          ariaLabelPrev: param?.carouselControls.ariaLabelPrev,
          ariaLabelNext: param?.carouselControls.ariaLabelNext,
        };
        break;
      default:
    }

    return {
      ...item,
      key: item.structure + index,
      structure: item.structure,
      properties: itemParsedData,
    };
  });

  return result;
};

export default parseUmbracoQuizIncomeData;
